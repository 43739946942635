import React from "react";
import { API, HEADER_HEIGHT } from "../../utils/constants";
import { useState, useEffect } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import {
  Button,
  Box,
  LinearProgress,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Pagination,
} from "@mui/material";
import EventCard from "components/EventCard/EventCard";
import { Search, FilterList } from "@mui/icons-material";
import { getDynamicRoute, getRoute } from "utils/routes";
import { useNavigate } from "react-router-dom";
import { convertToHierarchy } from "utils/DirectoryListing";
import XMLParser from "react-xml-parser";
import { useMetadataContext } from "context/MetadataContext";
import Noise from "assets/noise.svg";
import moment from "moment";
import { motion } from "framer-motion";
import ScaleUpOnHover from "utils/Animations/ScaleUpOnHover";
import MultipleSelectChip from "components/Reusables/MultiSelect";
import BACKGROUND_IMG from "assets/EOS_PRODUCT.png";
import EOS_RS_LOGO from "assets/EOS-RS-Logo.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Dashboard from "components/Home/Dashboard";

const itemsPerPage = 8;

const HomePage = () => {
  const metadata = useMetadataContext();
  const Navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(moment().subtract(6, "months"));
  const [endDate, setEndDate] = useState(moment());
  const [filter, setFilter] = useState([]);

  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredMetadata = metadata?.filter((item) => {
    return (
      item.product_list[0].prod_desc
        .toLowerCase()
        .includes(query.toLowerCase()) &&
      (filter.length == 0 ||
        item.event_type_tags.some((r) => {
          return filter.map((i) => i.toLowerCase()).includes(r);
        }))
    );
  });

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredMetadata?.slice(startIndex, endIndex);

  useEffect(() => {
    setPage(1);
  }, [filteredMetadata?.length]);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const mostRecentMetadata = metadata?.filter((item) => {
    const itemDate = moment(item.event_start);
    return itemDate.isAfter(startDate) && itemDate.isBefore(endDate);
  });

  const MapEventCards = (inputData) => {
    return inputData?.map((item) => {
      var latestProduct = item.product_list.find((data) => {
        return data.isLatest == true;
      });

      // console.log(item);
      return (
        <EventCard
          Title={item.event_display_name}
          Image={latestProduct.prod_main_png}
          Description={latestProduct.prod_desc}
          Date={`${item.event_start} | ${item.event_end}`}
          LastUpdated={latestProduct.prod_date}
          Tags={item.event_type_tags}
          key={item.event_name}
          onClick={() => {
            Navigate(getDynamicRoute("eventproduct", item.event_name));
          }}
        />
      );
    });
  };

  return (
    <Box>
      <AppHeader />
      <Box
        sx={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05)), url(${BACKGROUND_IMG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            background: "#00000088",
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <img src={EOS_RS_LOGO} height={"80px"} />
          <Typography
            variant="h5"
            textAlign="center"
            width="60%"
            // fontWeight="800"
            color="white"
          >
            The EOS Remote Sensing Lab (EOS-RS) is a flagship laboratory at the
            Earth Observatory of Singapore. We use satellite remote-sensing data
            for rapid disaster response and hazard monitoring.
          </Typography>
          <Button
            variant="outlined"
            // type="primary"

            color="primary"
            sx={{ width: "50%", borderRadius: "5rem", marginTop: "2rem" }}
            onClick={(e) => {
              let events = document
                .getElementById("events")
                .getBoundingClientRect();
              e.preventDefault(); // Stop Page Reloading
              events &&
                window.scrollTo({
                  behavior: "smooth",
                  top:
                    events.top -
                    document.body.getBoundingClientRect().top -
                    parseInt(HEADER_HEIGHT, 10),
                });
            }}
            // onClick={() => {
            //   Navigate(getRoute("home"));
            // }}
            // href="https://earthobservatory.sg/research/centres-labs/eos-rs"
          >
            View products
          </Button>
          <IconButton size="large">
            <KeyboardDoubleArrowDownIcon />
          </IconButton>
        </Box>
      </Box>
      <Dashboard />
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: " column",
          flexGrow: 1,
          padding: "2rem",
          gap: "3rem",
          paddingTop: HEADER_HEIGHT,
        }}
      ></Box> */}
    </Box>
  );
};

export default HomePage;
