//Default constants for the API URL and AUTH_TOKEN name for local storage
export const HOST = "https://sf.earthobservatory.sg";
export const AVATAR_API = "https://ui-avatars.com/api";
export const METADATA_API =
  // "https://aria-sg-products.s3-ap-southeast-1.amazonaws.com/metadata.all.json";
  process.env.REACT_APP_METADATA_API;
export const API = HOST + "/api";
export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";
export const GRAPHQL = HOST + "/graphql";

export const HEADER_HEIGHT = "76px";
export const CSRF_COOKIE_NAME = "csrftoken";
export const SARFINDER_BE_API_URL = process.env.REACT_APP_SARFINDER_BE_API_URL;
