import { red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { motion } from "framer-motion";

const GearAnimation = () => {
  return (
    <motion.div
      style={{ width: "fit-content" }}
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
    >
      <SettingsRoundedIcon
        fontSize={"large"}
        sx={{ color: "grey", fontSize: 140 }}
      />
    </motion.div>
  );
};

const HeartAnimation = () => {
  return (
    <motion.div
      style={{ width: "fit-content" }}
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ repeat: Infinity, duration: 1.5 }}
    >
      <FavoriteIcon fontSize={"large"} sx={{ color: "red", fontSize: 140 }} />
    </motion.div>
  );
};

const carVariants = {
  initial: {
    x: 0,
    rotate: -20,
  },
  animate: {
    x: [0, 350, 300, -75, 300, -150],
    rotate: 10,
    transition: {
      x: {
        type: "spring",
        stiffness: 50,
        damping: 10,
        repeat: Infinity,
        repeatType: "reverse",
      },
      rotate: {
        duration: 2,
        ease: "linear",
        repeat: Infinity,
        repeatType: "reverse",
      },
    },
  },
};

export const loadingText = [
  {
    icon: <GearAnimation />,
    top_text: "Gearing up",
    bottom_text: "Things nvr work when you need em 😭",
  },

  {
    icon: <GearAnimation />,
    top_text: "Relax, we're handling it",
    bottom_text: "You can take a quick coffee break ☕️",
  },

  {
    icon: <HeartAnimation />,
    top_text: "Woah there, searching for a lotta stuff eh?",
    bottom_text: "Hopefully the server doesn't give up 😶‍🌫️",
  },

  {
    icon: <HeartAnimation />,
    top_text: "Live. Laugh. Love.",
    bottom_text: "But most importantly AWS compute resources 👾",
  },
];
