import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Fade,
  Grow,
  Slide,
  Snackbar,
  AppBar,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AppHeader from "components/AppHeader/AppHeader";
import { useUser } from "provider/auth/useUser";
import { useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";

const LinkedInCaptionPage = () => {
  const userQuery = useUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [productCaption, setProductCaption] = useState("");
  const [context, setContext] = useState("");
  const [additionalPrompt, setAdditionalPrompt] = useState("");
  const [productLink, setProductLink] = useState("");
  const [result, setResult] = useState("");
  const [copyFeedback, setCopyFeedback] = useState(false);

  useEffect(() => {
    if (
      !userQuery.isPending &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  const mutation = useMutation({
    mutationKey: ["generateLinkedInCaption"], // Provide a unique key for this mutation
    mutationFn: async (data) => {
      const response = await fetch(
        `${SARFINDER_BE_API_URL}/linkedin/caption/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        if (response.status === 400) {
          const errorData = await response.json();
          throw new Error(
            `Bad Request: ${errorData.message || "Invalid input"}`
          );
        }
        throw new Error("An error occurred while generating the caption");
      }
      return response.json();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setResult(""); // Clear previous result
    mutation.mutate(
      {
        product_caption: productCaption,
        context,
        additional_prompt: additionalPrompt,
        product_link: productLink,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          setResult(data.linkedin_caption);
          enqueueSnackbar("LinkedIn caption generated successfully!", {
            variant: "success",
          });
        },
        onError: (error) => {
          console.log(error);
          if (error.message.startsWith("Bad Request")) {
            setResult("Error: Invalid input provided");
            enqueueSnackbar("Error: Invalid input provided", {
              variant: "error",
            });
          } else {
            setResult("Error generating caption");
            enqueueSnackbar("Error generating caption", { variant: "error" });
          }
        },
      }
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(result);
    setCopyFeedback(true);
    setTimeout(() => setCopyFeedback(false), 2000); // Show feedback for 2 seconds
    enqueueSnackbar("Copied to clipboard!", { variant: "info" });
  };

  return (
    <Container maxWidth="md" sx={{ paddingTop: `3rem`, overflowY: "auto" }}>
      <AppHeader />
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Generate LinkedIn Caption
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Product Caption"
            value={productCaption}
            onChange={(e) => setProductCaption(e.target.value)}
            margin="normal"
            required
            multiline
            minRows={1} // minimum number of rows
            maxRows={10} // maximum number of rows
            disabled={mutation.isPending}
          />
          <TextField
            fullWidth
            label="Context"
            value={context}
            onChange={(e) => setContext(e.target.value)}
            margin="normal"
            required
            multiline
            minRows={1} // minimum number of rows
            maxRows={10} // maximum number of rows
            disabled={mutation.isPending}
          />
          <TextField
            fullWidth
            label="Additional Prompt"
            value={additionalPrompt}
            onChange={(e) => setAdditionalPrompt(e.target.value)}
            margin="normal"
            required
            disabled={mutation.isPending}
          />
          <TextField
            fullWidth
            label="Product Link"
            value={productLink}
            onChange={(e) => setProductLink(e.target.value)}
            margin="normal"
            required
            disabled={mutation.isPending}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
            disabled={mutation.isPending}
          >
            {mutation.isPending ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Generate LinkedIn Caption"
            )}
          </Button>
        </form>

        {result && (
          <Grow in={true}>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Generated Caption:</Typography>
              <TextField
                fullWidth
                multiline
                minRows={1} // minimum number of rows
                maxRows={10} // maximum number of rows
                value={result}
                InputProps={{
                  readOnly: false,
                }}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={handleCopy}
              >
                {copyFeedback ? "Copied!" : "Copy to Clipboard"}
              </Button>
            </Box>
          </Grow>
        )}
      </Box>
    </Container>
  );
};

export default LinkedInCaptionPage;
