import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import SATELLITE_IMG from "assets/satellite-stock.jpeg";
import AppHeader from "components/AppHeader/AppHeader";
import { useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";
import { useLogin } from "provider/auth/useLogin";
import { useUser } from "provider/auth/useUser";
import { useForm, Controller } from "react-hook-form";

export default function Login() {
  const userQuery = useUser();
  const navigate = useNavigate();
  const { login, isLoading: isLoginLoading } = useLogin();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!userQuery.isLoading && userQuery.data?.username) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch (error) {
      setError("identifier", {
        type: "manual",
        message:
          "Invalid credentials. Please check your username/email and password.",
      });
      setError("password", {
        type: "manual",
        message:
          "Invalid credentials. Please check your username/email and password.",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <AppHeader />
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ maxWidth: 400, margin: "auto" }}
            >
              <Typography variant="h4" gutterBottom>
                Login
              </Typography>
              <Controller
                name="identifier"
                control={control}
                defaultValue=""
                rules={{ required: "Username or Email is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Username or Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.identifier}
                    helperText={
                      errors.identifier ? errors.identifier.message : " "
                    }
                    FormHelperTextProps={{
                      sx: { minHeight: "1.5em" },
                    }}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : " "}
                    FormHelperTextProps={{
                      sx: { minHeight: "1.5em" },
                    }}
                  />
                )}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoginLoading}
                fullWidth
                sx={{ mt: 2, height: 56 }}
              >
                {isLoginLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </Button>
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <Button
                  variant="text"
                  color="secondary"
                  disabled={isLoginLoading}
                  onClick={() => navigate(getRoute("signup"))}
                  sx={{
                    background: "transparent",
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.04)",
                      textDecoration: "underline",
                    },
                  }}
                >
                  I don't have an account
                </Button>
                <Box sx={{ mt: 1, textAlign: "center" }}>
                  <Button
                    variant="text"
                    color="secondary"
                    disabled={isLoginLoading}
                    onClick={() => navigate(getRoute("password_reset"))}
                    sx={{
                      background: "transparent",
                      "&:hover": {
                        background: "rgba(0, 0, 0, 0.04)",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    I forgot my password
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "125%",
                objectFit: "cover",
              }}
              alt="Satellite"
              src={SATELLITE_IMG}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
