import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";
import { getRoute } from "utils/routes";

export function useLogin() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const loginMutation = useMutation({
    mutationFn: (value) => {
      return fetch(`${SARFINDER_BE_API_URL}/accounts/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(value),
        credentials: "include",
      }).then(async (res) => {
        if (!res.ok) {
          const errorData = await res.json();
          throw errorData;
        }
        return res.json();
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["auth:user"], data);
      setTimeout(() => {
        queryClient.refetchQueries(["auth:user"], { active: true });
      }, 30 * 1000);
      navigate(getRoute("home"));
    },
  });

  const login = async (values) => {
    return await loginMutation.mutateAsync(values);
  };

  return {
    login,
    isLoading: loginMutation.isPending,
    error: loginMutation.error,
  };
}
