import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Pagination,
  List,
  Grid,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Button,
} from "@mui/material";
import { Search, LocationOn } from "@mui/icons-material";

import moment from "moment";
import { motion } from "framer-motion";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import {
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  Public as PublicIcon,
} from "@mui/icons-material";
import L from "leaflet";
import MarkerIcon from "assets/Marker-Icon.png";
import "leaflet.heat";

const customIcon = new L.Icon({
  iconUrl: MarkerIcon,
  iconSize: [18, 24], // Set the size of the icon
  iconAnchor: [12, 24], // Set the anchor point of the icon
});

const itemsPerPage = 8;

const Dashboard = () => {
  const [events, setEvents] = useState([]);
  const [usgsEvents, setUsgsEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);

  const [heatmapData, setHeatmapData] = useState([]);

  const HeatmapLayer = ({ points, options }) => {
    const map = useMap();
    const heatmapRef = useRef(null);

    useEffect(() => {
      if (!heatmapRef.current) {
        heatmapRef.current = L.heatLayer(points, options).addTo(map);
      } else {
        heatmapRef.current.setLatLngs(points);
      }

      return () => {
        map.removeLayer(heatmapRef.current);
      };
    }, [points, options, map]);

    return null; // No need to render anything in React
  };

  useEffect(() => {
    const formattedData = usgsEvents.map((event) => {
      return [
        event.geometry.coordinates[1],
        event.geometry.coordinates[0],
        event.properties.mag / 10.0, // Intensity of the heatmap point
      ];
    });
    setHeatmapData(formattedData);
  }, [usgsEvents]);

  useEffect(() => {
    fetchRecentEarthquakes().then((data) => setUsgsEvents(data));

    const dummyEventData = [
      {
        id: 1,
        title: "Earthquake in California",
        image:
          "https://image.cnbcfm.com/api/v1/image/107190011-1675773362930-gettyimages-1246861557-AFP_338P6DU.jpeg?v=1675925989",
        description:
          "A powerful earthquake struck California, causing widespread damage.",
        date: "2024-02-13T09:00:00Z",
        tags: ["earthquake", "California", "natural disaster"],
      },
      {
        id: 2,
        title: "Hurricane in Florida",
        image:
          "https://www.tallahassee.com/gcdn/presto/2022/11/12/USAT/10ba8b3f-d470-4864-800a-a370000aac69-AFP_AFP_32NC9B4.jpg?crop=6239,3510,x0,y317&width=3200&height=1801&format=pjpg&auto=webp",
        description:
          "Category 5 hurricane hits Florida, leading to evacuation orders.",
        date: "2024-02-10T12:00:00Z",
        tags: ["hurricane", "Florida", "natural disaster"],
      },
      // Add more events as needed
    ];

    setEvents(dummyEventData);
  }, []);

  const fetchRecentEarthquakes = async () => {
    const url = "https://earthquake.usgs.gov/fdsnws/event/1/query";
    const params = new URLSearchParams({
      format: "geojson",
      starttime: moment().subtract(7, "days").format("YYYY-MM-DD"), // Start time 7 days ago
      endtime: moment().format("YYYY-MM-DD"), // Current date
      minmagnitude: 0.0, // Minimum magnitude
    });

    try {
      const response = await fetch(`${url}?${params}`);
      if (!response.ok) {
        throw new Error("Failed to fetch earthquake data");
      }
      const data = await response.json();
      return data.features; // Array of earthquake events
    } catch (error) {
      console.error("Error fetching earthquake data:", error);
      return []; // Return an empty array or handle the error as needed
    }
  };

  //   useEffect(() => {
  //     // Fetch recent disaster events data from an API
  //     // Example:
  //     // fetchRecentEvents().then((data) => setEvents(data));
  //     // For demonstration, initializing with dummy data
  //
  //   }, []);

  const handleCardClick = (event) => {
    const eventId = event.currentTarget.getAttribute("data-event-id");
    // Implement logic to handle card click (e.g., navigate to event details page)
    alert(`Clicked event with ID: ${eventId}`);
  };

  //   const handleMarkerClick = (event) => {
  //     const eventId = event.target.options.eventId;
  //     console.log("clicked");
  //     setSelectedEvent(events.find((event) => event.id === eventId));
  //   };
  const handleMarkerClick = (event) => {
    setSelectedEvent(event);
  };

  const handlePopupClose = () => {
    setSelectedEvent(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(query.toLowerCase())
  );

  const paginatedEvents = usgsEvents.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Recent Disaster Events
      </Typography>
      <Box mb={2}>
        <TextField
          label="Search events"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
        />
      </Box>

      <Grid container spacing={2}>
        {filteredEvents.map((event) => (
          <Grid item key={event.id} xs={12} md={6} lg={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={event.image}
                alt={event.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {event.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  {event.description}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Chip label={moment(event.date).format("MMM DD, YYYY")} />
                  <Box>
                    {event.tags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1, mb: 1 }}
                      />
                    ))}
                  </Box>
                </Box>
              </CardContent>
              <Box p={2}>
                <Button
                  variant="outlined"
                  onClick={() => alert("View details clicked")}
                  fullWidth
                >
                  View Details
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" flexDirection="column" alignItems="center">
        <MapContainer
          center={[0, 0]}
          zoom={2}
          style={{ height: "400px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* {usgsEvents.map((event) => (
            <Marker
              key={event.id}
              position={[
                event.geometry.coordinates[1],
                event.geometry.coordinates[0],
              ]}
              eventID={event.id}
              //   onClick={() => handleMarkerClick(event)}
              onClick={() => handleMarkerClick(event)}
              icon={customIcon}
            >
              <Popup
                position={[
                  event.geometry.coordinates[1],
                  event.geometry.coordinates[0],
                ]}
                onClose={handlePopupClose}
              >
                <Typography variant="subtitle1">
                  {moment(event.properties.time).format("MMM DD, YYYY")}
                </Typography>
                <Typography variant="body2">
                  {event.properties.place}
                </Typography>
                <Typography variant="body2">
                  Magnitude: {event.properties.mag}
                </Typography>
              </Popup>
            </Marker>
          ))} */}
          {selectedEvent && (
            <Popup
              position={[
                selectedEvent.geometry.coordinates[1],
                selectedEvent.geometry.coordinates[0],
              ]}
              onClose={handlePopupClose}
            >
              <Typography variant="subtitle1">
                {moment(selectedEvent.properties.time).format("MMM DD, YYYY")}
              </Typography>
              <Typography variant="body2">
                {selectedEvent.properties.place}
              </Typography>
              <Typography variant="body2">
                Magnitude: {selectedEvent.properties.mag}
              </Typography>
            </Popup>
          )}
          {heatmapData.length > 0 && (
            <HeatmapLayer
              points={heatmapData}
              options={{
                blur: 1,
                maxZoom: 10,
                gradient: {
                  0.1: "blue",
                  0.2: "lime",
                  0.3: "yellow",
                  0.5: "red",
                  1: "black",
                },
              }}
            />
          )}
        </MapContainer>

        <Box width="100%" mt={2}>
          <Grid container spacing={2}>
            {paginatedEvents.map((event) => (
              <Grid item xs={12} sm={6} key={event.id}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  <ListItem button onClick={() => setSelectedEvent(event)}>
                    <ListItemIcon>
                      <LocationOn />
                    </ListItemIcon>
                    <ListItemText
                      primary={moment(event.properties.time).format(
                        "MMM DD, YYYY"
                      )}
                      secondary={`${event.properties.place} | Magnitude: ${event.properties.mag}`}
                    />
                  </ListItem>
                </motion.div>
              </Grid>
            ))}
          </Grid>
          <Box mt={3} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(usgsEvents.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
