import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  FloatingSideButton,
  FloatingSidePeekCard,
} from "components/MapPage/FloatingSideCard";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { enqueueSnackbar } from "notistack";

const SidePanel = ({ openLayers, setOpenLayers, children }) => {
  return (
    <>
      <FloatingSideButton
        isOpen={openLayers}
        onClick={() => {
          setOpenLayers(!openLayers);
        }}
      />
      <FloatingSidePeekCard isOpen={openLayers}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              setOpenLayers(!openLayers);
            }}
          >
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="h5">Search</Typography>
        </Box>
        <Stack
          sx={{
            padding: "0.5rem",
            gap: "1rem",
            overflow: "auto",
            flex: 1,
          }}
        >
          {children}
        </Stack>
      </FloatingSidePeekCard>
    </>
  );
};

export default SidePanel;
