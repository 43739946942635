import { useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { MinimapControl } from "./MinimapControl";

function MapContents({ setSelectionArea }) {
  const featureGroupRef = useRef(null);

  const switchPolygons = (layer) => {
    featureGroupRef.current.clearLayers();
    featureGroupRef.current.addLayer(layer);
  };

  const handleNewPolygonCreation = (e) => {
    const { layer } = e;

    switchPolygons(layer);
    setSelectionArea(layer.getBounds());

    console.log("LAYER BOUNDARIES");
    console.log(layer.getBounds());
    console.log("LAT LONGS");
    console.log(layer.getLatLngs());
  };

  const handlePolygonEdited = (e) => {
    const layers = e.layers;
    layers.eachLayer((layer) => {
      setSelectionArea(layer.getBounds());
    });
  };

  return (
    <LayerGroup position="topright">
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onCreated={handleNewPolygonCreation}
          onEdited={handlePolygonEdited}
          draw={{
            rectangle: true,
            polygon: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polyline: false,
          }}
          edit={{
            edit: true,
            remove: true,
          }}
        />
      </FeatureGroup>
      <MinimapControl position="bottomright" />
      <LayersControl>
        <LayersControl.BaseLayer checked name="Jawg.Matrix">
          <TileLayer
            url="https://tile.jawg.io/jawg-matrix/{z}/{x}/{y}{r}.png?access-token={accessToken}"
            attribution={`<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
            maxNativeZoom={22}
            accessToken="fp7uXbBF34PcZi0JgiDprLOSdyVUkVNioPVsElDHC7QA6Gudtdtzu6lLRjZKgq4g"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Google Hybrid Map">
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            attribution={`<a href="https://www.crawfordcountypa.net/GIS/Documents/Crawford%20County%20PA%20GIS%20Web_Data%20Disclaimer.pdf?Web=1" title="Crawford County, PA GIS Web/Data" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy;`}
            maxNativeZoom={22}
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="ESRI Satellite">
          <TileLayer
            url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution={`<a href="hhttps://www.esri.com/en-us/legal/terms/full-master-agreement" title="This work is licensed under the Esri Master License Agreement." target="_blank">&copy; <b>ESRI</b>Maps</a> &copy;`}
            maxNativeZoom={21}
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="CartoDB.DarkMatter">
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            attribution={`&copy; <a href="ttps://carto.com/attributions">CartoDB</a> contributors &copy;`}
            subdomains="abcd"
            maxNativeZoom={20}
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay name="World Hillshade (Dark)">
          <TileLayer
            url="https://server.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}"
            attribution={`<a href="https://www.arcgis.com" title="Arcgis" target="_blank">&copy; <b>Arcgis</b>Maps</a> &copy; Sources: Esri, Maxar, Airbus DS, USGS, NGA, NASA, CGIAR, N Robinson, NCEAS, NLS, OS, NMA, Geodatastyrelsen, Rijkswaterstaat, GSA, Geoland, FEMA, Intermap, and the GIS user community contributors`}
            maxNativeZoom={15}
            opacity={0.3} // Adjust the opacity as needed
          />
        </LayersControl.Overlay>
      </LayersControl>
    </LayerGroup>
  );
}

export default function MapView({
  setSelectionArea,
  geoJsonRef,
  setOpenLayers,
  bounds,
  children,
}) {
  return (
    <MapContainer
      style={{
        height: "100%",
        borderRadius: "1rem",
        zIndex: 10,
      }}
      center={bounds ? null : [0, 0]}
      zoom={bounds ? 10 : 2}
      scrollWheelZoom={true}
      bounds={bounds}
      worldCopyJump
    >
      {children}
      <MapContents setSelectionArea={setSelectionArea} />
    </MapContainer>
  );
}
