//Top Bar that deals with user login
import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../Reusables/NavBar";
import { Box, Button, Toolbar, Typography, AppBar } from "@mui/material";
import { HEADER_HEIGHT } from "utils/constants";
import logo from "assets/EOS Logo.png";
import Noise from "assets/noise.svg";
import { useTheme } from "@mui/material/styles";
import CustomToolbar from "./Toolbar";
import styled from "@emotion/styled";
import MuiAppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
const drawerWidth = 400;

const AppHeader = forwardRef(
  ({ isMapPage = false, handleDrawerOpen, open }, ref) => {
    // const Navigate = useNavigate();
    // const theme = useTheme();
    // function EOSIcon(props) {
    //   return (
    //     <img
    //       // resizeMode="contain"
    //       src={logo}
    //       alt="logo"
    //       style={{ width: "165px", height: "64px" }}
    //     />
    //   );
    // }

    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => ({
      // backgroundColor: `#424242AA`,
      backgroundColor: "black",
      height: HEADER_HEIGHT,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));

    return (
      <AppBar
        ref={ref}
        position="fixed"
        sx={{
          height: HEADER_HEIGHT,
          zIndex: 100,
          backgroundColor: "black",
          // backgroundColor: `#424242AA`,
          // backdropFilter: "blur(8px)",
        }}
      >
        <CustomToolbar
          isMapPage={isMapPage}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
        />
      </AppBar>
    );
  }
);

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const CustomDrawer = ({ open, children }) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      {children}
    </Drawer>
  );
};

export default AppHeader;
