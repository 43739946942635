import { useQueries } from "@tanstack/react-query";
import { SARFINDER_BE_API_URL } from "utils/constants";

const MAX_FAILURES = 30;

export function useUser() {
  const queries = useQueries({
    queries: [
      {
        queryKey: ["auth:csrf"],
        queryFn: () =>
          fetch(`${SARFINDER_BE_API_URL}/accounts/csrf/`, {
            credentials: "include",
          }).then((res) => {
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.json();
          }),
        retry: (failureCount) => failureCount < MAX_FAILURES,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
        staleTime: 1 * (60 * 1000),
      },
      {
        queryKey: ["auth:user"],
        queryFn: () =>
          fetch(`${SARFINDER_BE_API_URL}/accounts/me/`, {
            credentials: "include",
          }).then((res) => {
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.json();
          }),
        retry: (failureCount) => failureCount < MAX_FAILURES,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
        // * Consider data to be "fresh" for 1 minute.
        staleTime: 1 * (60 * 1000),
      },
    ],
  });

  // Directly return the userQuery object
  return queries[1]; // This is the userQuery
}
