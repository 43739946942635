import React, { useState, useMemo } from "react";
import {
  Slide,
  Drawer,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  TablePagination,
  Button,
  MenuItem,
  Menu,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { visuallyHidden } from "@mui/utils";
import tokml from "tokml";

const headCells = [
  {
    id: "identifier",
    numeric: false,
    disablePadding: true,
    label: "Identifier",
  },
  {
    id: "orbitnumber",
    numeric: true,
    disablePadding: false,
    label: "Orbit Number",
  },
  {
    id: "beginposition",
    numeric: false,
    disablePadding: false,
    label: "Begin Position",
  },
  {
    id: "endposition",
    numeric: false,
    disablePadding: false,
    label: "End Position",
  },
  {
    id: "relativeorbitnumber",
    numeric: true,
    disablePadding: false,
    label: "Relative Orbit Number",
  },
  {
    id: "framenumber",
    numeric: true,
    disablePadding: false,
    label: "Frame Number",
  },
  { id: "beammode", numeric: false, disablePadding: false, label: "Beam Mode" },
  {
    id: "orbitdirection",
    numeric: false,
    disablePadding: false,
    label: "Orbit Direction",
  },
  {
    id: "antennalookdirection",
    numeric: false,
    disablePadding: false,
    label: "Antenna Look Direction",
  },
  {
    id: "polarisationchannels",
    numeric: false,
    disablePadding: false,
    label: "Polarisation Channels",
  },
  {
    id: "acquisitiontype",
    numeric: false,
    disablePadding: false,
    label: "Acquisition Type",
  },
  {
    id: "platformshortname",
    numeric: false,
    disablePadding: false,
    label: "Platform Short Name",
  },
  {
    id: "instrumentshortname",
    numeric: false,
    disablePadding: false,
    label: "Instrument Short Name",
  },
  {
    id: "processingdate",
    numeric: false,
    disablePadding: false,
    label: "Processing Date",
  },
  {
    id: "polarisationmode",
    numeric: false,
    disablePadding: false,
    label: "Polarisation Mode",
  },
  {
    id: "gekko_path",
    numeric: false,
    disablePadding: false,
    label: "Gekko Path",
  },
];

const SlidingTable = ({ jsonData }) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleDownloadKml = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

    jsonData.forEach((platform, index) => {
      const fileName = `data_${currentDate}_${index}.kml`; // Create a relevant file name with the date
      const kmlString = `data:text/xml;charset=utf-8,${encodeURIComponent(
        jsonToKml(platform)
      )}`;

      const link = document.createElement("a");
      link.href = kmlString;
      link.download = fileName;
      link.click();
    });
  };

  const jsonToKml = (json) => {
    // Convert JSON to KML format
    // This is a placeholder function. You need to implement the actual conversion logic.
    var kmlDocumentName = tokml(json, {
      documentName: "Sarfinder Metadata",
      documentDescription:
        "This is a KML file generated from the metadata of the Sarfinder platform",
    });

    return kmlDocumentName;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadJson = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

    jsonData.forEach((platform, index) => {
      const fileName = `data_${currentDate}_${index}.json`; // Create a relevant file name with the date
      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(platform, null, 2)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = fileName;
      link.click();
    });
  };

  const rows = jsonData?.flatMap((platformName) => {
    return platformName?.features?.map((item) => item?.properties);
  });

  const visibleRows = useMemo(() => {
    return rows
      .slice()
      .sort((a, b) => {
        if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
          return order === "desc"
            ? b[orderBy].localeCompare(a[orderBy])
            : a[orderBy].localeCompare(b[orderBy]);
        } else {
          return order === "desc"
            ? b[orderBy] - a[orderBy]
            : a[orderBy] - b[orderBy];
        }
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage, rows]);

  console.log("visibleRows", visibleRows);
  return (
    <>
      <IconButton
        onClick={handleToggleDrawer}
        color="primary"
        aria-label="open table drawer"
        sx={{
          position: "fixed",
          bottom: open ? "50%" : "10px",
          left: "50%",
          transform: "translateX(-50%)",
          transition: "bottom 0.3s",
          zIndex: 999,
          width: "60px",
          height: "60px",
          backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }}
      >
        {open ? (
          <ArrowDownwardIcon fontSize="large" />
        ) : (
          <ArrowUpwardIcon fontSize="large" />
        )}
      </IconButton>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleToggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            height: "50%",
            bottom: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        }}
      >
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Typography variant="h6">Table View</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMenuClick}
                >
                  Download Options
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadJson();
                      handleMenuClose();
                    }}
                  >
                    Download GeoJSON
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadKml();
                      handleMenuClose();
                    }}
                  >
                    Download KML
                  </MenuItem>
                </Menu>
                <IconButton onClick={handleToggleDrawer}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((row) => (
                      <TableRow key={row.identifier}>
                        <TableCell component="th" scope="row">
                          {row.identifier}
                        </TableCell>
                        <TableCell align="right">{row.orbitnumber}</TableCell>
                        <TableCell align="right">{row.beginposition}</TableCell>
                        <TableCell align="right">{row.endposition}</TableCell>
                        <TableCell align="right">
                          {row.relativeorbitnumber}
                        </TableCell>
                        <TableCell align="right">{row.framenumber}</TableCell>
                        <TableCell align="right">{row.beammode}</TableCell>
                        <TableCell align="right">
                          {row.orbitdirection}
                        </TableCell>
                        <TableCell align="right">
                          {row.antennalookdirection}
                        </TableCell>
                        <TableCell align="right">
                          {row.polarisationchannels}
                        </TableCell>
                        <TableCell align="right">
                          {row.acquisitiontype}
                        </TableCell>
                        <TableCell align="right">{row.status}</TableCell>
                        <TableCell align="right">
                          {row.platformshortname}
                        </TableCell>
                        <TableCell align="right">
                          {row.instrumentshortname}
                        </TableCell>
                        <TableCell align="right">
                          {row.processingdate}
                        </TableCell>
                        <TableCell align="right">
                          {row.polarisationmode}
                        </TableCell>
                        <TableCell align="right">{row.gekko_path}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Slide>
      </Drawer>
    </>
  );
};

export default SlidingTable;
