import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";

export function useRegister() {
  const registerMutation = useMutation({
    mutationFn: (userData) => {
      return fetch(`${SARFINDER_BE_API_URL}/accounts/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        credentials: "include",
        body: JSON.stringify(userData),
      }).then(async (res) => {
        if (!res.ok) {
          const errorData = await res.json();
          throw errorData;
        }
        return res.json();
      });
    },
  });

  const register = async (userData) => {
    return await registerMutation.mutateAsync(userData);
  };

  return {
    register,
    isLoading: registerMutation.isPending,
    error: registerMutation.error,
  };
}
