import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useEffect, useRef, useState } from "react";

import "leaflet-area-select";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { HEADER_HEIGHT } from "utils/constants";

import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useLoadingScreen } from "provider/LoadingScreen";
import { parseXmlError } from "utils/helper";

//DPM1
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import MapView from "components/MapPage/Map/MapView";
import { useUser } from "provider/auth/useUser";
import { useNavigate } from "react-router-dom";
import { getRoute } from "utils/routes";
import SidePanel from "components/Sarfinder/Sidepanel";
import { GeoJSON } from "react-leaflet";
import SlidingTable from "components/Sarfinder/SlidingTable";
import AppHeader, {
  CustomDrawer,
  DrawerHeader,
  Main,
} from "components/AppHeader/AppHeader";

const getCenterPoint = (event_bbox) => {
  return [
    (event_bbox[0][0] + event_bbox[1][0]) / 2.0,
    (event_bbox[0][1] + event_bbox[1][1]) / 2.0,
  ];
};

function SarfinderPage() {
  const userQuery = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userQuery.isLoading &&
      !userQuery.data?.username &&
      !(userQuery.data?.is_staff || userQuery.data?.is_superuser)
    ) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  const theme = useTheme();
  const [state, setState] = useState();

  const [sidebarDescription, setSidebarDescription] = useState({});
  const appBarRef = useRef(null);
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [openLayers, setOpenLayers] = useState(false);
  const [selectionArea, setSelectionArea] = useState();
  const { showLoadingScreen, hideLoadingScreen } = useLoadingScreen();

  //useState created for DPM1 button
  const [isSubmitHover, setIsSubmitHover] = useState(false);
  const [aoi, setAoi] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [jsonData, setJsonData] = useState([]);

  const geoJsonRef = useRef();

  const [formData, setFormData] = useState({
    platformName: [],
    beamMode: [],
    orbitDirection: "",
    relOrbitNumber: "",
    endRelOrbitNumber: "",
    frameNumber: "",
    endFrameNumber: "",
    identifier: "",
    startDateTime: moment.utc(),
    endDateTime: moment.utc(),

    //added for DPM1
    directoryName: "",
    masterDate: "",
    eventDate: "",
  });

  var cqlQueryDict = {};
  var metadata = [];

  const outline_color = ["#be93e677", "#FFA50077", "#00800077"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateTimeChange = (date, key) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: date,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOpenLayers(false);

    // Handle form submission here
    console.log(formData);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, [appBarRef.current]);

  function serialize(obj) {
    var str = [];
    if (Object.keys(obj).length != 0) {
      for (const key of Object.keys(obj))
        str.push(encodeURIComponent(obj[key]));
      return "&CQL_FILTER=" + str.join(" AND ");
    }

    return "";
  }

  function bboxQuery() {
    if (selectionArea?.toBBoxString())
      return "BBOX(geometry, " + selectionArea.toBBoxString() + ",'EPSG:4326')";
    else return "";
  }

  //GETS THE TIME RANGE FROM USER INPUT
  function timeQuery() {
    const startDate = formData?.startDateTime.utc().format();
    const endDate = formData?.endDateTime.utc().format();

    if (endDate && startDate) {
      return (
        "endposition AFTER " +
        startDate +
        " AND beginposition BEFORE " +
        endDate
      );
    } else if (startDate) {
      return "endposition AFTER " + startDate;
    } else if (endDate) {
      return "beginposition BEFORE " + endDate;
    } else {
      return "";
    }
  }

  function appendOverpassTime(date, timeID) {
    var time = document.getElementById(timeID).value;
    if (time) {
      return date + "T" + time;
    } else {
      return date + "T00:00";
    }
  }

  //RETRIEVES ALL THE FILTERS FORM THE INPUT FIELDS
  function getFilters() {
    valueCheck("timefilter", timeQuery());
    valueCheck("bboxfilter", bboxQuery());
    fieldCheck("orbitdirection", "orbitDirection");
    fieldCheck("identifier", "identifier");
    fieldCheck("beammode", "beamMode");
    fieldRangeCheck("framenumber", "frameNumber", "endFrameNumber");
    // fieldCheck("orbitnumber", "orbitNumber");
    fieldRangeCheck(
      "relativeorbitnumber",
      "relOrbitNumber",
      "endRelOrbitNumber"
    );
    return serialize(cqlQueryDict);
  }

  //FUNCTION TO ADD VALUE TO DICTIONARY IF THE VALUE FOR THE SPECIFIED KEY IS NOT EMPTY
  function valueCheck(key, value) {
    if (value) {
      cqlQueryDict[key] = value;
    }
  }

  //ADDS THE QUERY TO THE QUERY DICTIONARY IF TRUTHY
  function fieldCheck(key, field) {
    var value = formatQuery(key, formData[field]);
    if (value) {
      cqlQueryDict[key] = value;
    }
  }

  //ADDS THE RANGED QUERY TO THE QUERY DICTIONARY IF TRUTHY. WILL CALL FIELD CHECK, IF ONLY ONE VALUE KEYED IN
  function fieldRangeCheck(key, field, endField) {
    if (
      document.getElementById(field).value &&
      document.getElementById(endField).value
    ) {
      cqlQueryDict[key] =
        key +
        " BETWEEN " +
        document.getElementById(field).value +
        " AND " +
        document.getElementById(endField).value;
    } else {
      fieldCheck(key, field);
    }
  }

  //RETURNS AN EMPTY STRING IF NO INPUT VALUE. BUT RETURNS ""='VALUE'" IF GOT VALUE. AND IF IT HAS A RANGE, RETURN WITH RANGE FILTER
  function formatQuery(key, obj) {
    if (obj != "") return key + "='" + obj + "'";

    return "";
  }

  const GeoJSONMap = async (url) => {
    const response = await fetch(url);
    if (response.status == 200) {
      const data = await response.json();
      return data;
    } else {
      const xmlString = await response.text();

      throw new Error(parseXmlError(xmlString)?.exceptionText);
    }

    // fetch(url)
  };

  useEffect(() => {
    console.log(selectionArea);
  }, [selectionArea]);

  ////DPM1
  useEffect(() => {
    dpm1Get();
  }, [aoi, jobType]);

  const dpm1Get = async () => {
    console.log("my variable is:", jobType);

    //url for airflow variables
    let apiUrl = "";
    if (jobType == "dpm1") {
      apiUrl = "http://52.220.67.132:8080/api/v1/variables/DPM1_variables";
    } else if (jobType == "dpm2") {
      apiUrl = "http://52.220.67.132:8080/api/v1/variables/DPM2_variables";
    } else if (jobType == "fpm1") {
      apiUrl = "http://52.220.67.132:8080/api/v1/variables/FPM1_variables";
    } else if (jobType == "fpm2") {
      apiUrl = "http://52.220.67.132:8080/api/v1/variables/FPM2_variables";
    }
    console.log("my apiUrl is:", apiUrl);

    let start_date = formData.startDateTime._d.toISOString().split("T")[0];
    let end_date = formData.endDateTime._d.toISOString().split("T")[0];
    let date_suffix = "T00:00:00UTC";
    let new_start_date = start_date.concat(date_suffix);
    let new_end_date = end_date.concat(date_suffix);

    // get coordinates from selectionArea
    let bboxN = selectionArea._northEast.lat;
    let bboxE = selectionArea._northEast.lng;
    let bboxS = selectionArea._southWest.lat;
    let bboxW = selectionArea._southWest.lng;

    console.log("initial bbox:", bboxN);

    // get the location of the AOI
    let aoi_url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${bboxN}&longitude=${bboxE}&localityLanguage=en`;
    const aoi_requestOptions = {
      method: "GET",
    };

    await fetch(aoi_url, aoi_requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((aoi_data) => {
        console.log("my data is ", aoi_data.city, ",", aoi_data.countryName);
        let aoi_location = `${aoi_data.city} in ${aoi_data.countryName}`;
        console.log("initial bbox inside:", bboxN);
        setAoi(aoi_location);
        console.log("aoi is ", aoi);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const value = `{ 
        "dir_name" :  "${formData.directoryName}",
        "master_date" : ${formData.masterDate},
        "event_date" : ${formData.eventDate},
        "bboxS" : ${bboxS},
        "bboxN" : ${bboxN},
        "bboxW" : ${bboxW},
        "bboxE" : ${bboxE},
        "start" : "${new_start_date}",
        "end" : "${new_end_date}",
        "relativeOrbit" : ${formData.relOrbitNumber}
        
      }`;

    ///check dpm type and assign key values accordingly
    let dpm_key = "";
    if (jobType == "dpm1") {
      dpm_key = "DPM1_variables";
    } else if (jobType == "fpm1") {
      dpm_key = "FPM1_variables";
    } else if (jobType == "dpm2") {
      dpm_key = "DPM2_variables";
    } else if (jobType == "fpm2") {
      dpm_key = "FPM2_variables";
    }
    console.log("my dpm key is", dpm_key);

    const requestOptions = {
      method: "PATCH",
      mode: "cors",
      headers: {
        //use this website to encrypt username:password
        //https://www.base64encode.org/
        //in our case, username and password are the same, airflow, so encrypt airflow:airflow
        Authorization: "Basic YWlyZmxvdzphaXJmbG93",
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: "",
        key: dpm_key,
        value: value,
      }),
    };

    //to call dags
    let dagUrl = "";
    if (jobType == "dpm1") {
      dagUrl = "http://52.220.67.132:8080/api/v1/dags/DPM1/dagRuns";
    } else if (jobType == "fpm1") {
      dagUrl = "http://52.220.67.132:8080/api/v1/dags/FPM1/dagRuns";
    } else if (jobType == "dpm2") {
      dagUrl = "http://52.220.67.132:8080/api/v1/dags/DPM2/dagRuns";
    } else if (jobType == "fpm2") {
      dagUrl = "http://52.220.67.132:8080/api/v1/dags/FPM2/dagRuns";
    }
    console.log(" my apiURL test : ", dagUrl);
    const dag_requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        //use this website to encrypt username:password
        //https://www.base64encode.org/
        //in our case, username and password are the same, airflow, so encrypt airflow:airflow, out the BASIC word at the front
        Authorization: "Basic YWlyZmxvdzphaXJmbG93",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conf: {},
      }),
    };

    /// To check if selection area has been defined and submit jobs
    if (selectionArea !== null) {
      confirmAlert({
        title: `Disaster area is ${aoi}.`,
        message: `DPM variables is ${value}`,

        buttons: [
          {
            label: "Yes",
            onClick: () => {
              alert("Job Triggered");
              if (jobType == "dpm1") {
                window.open("http://52.220.67.132:8080/dags/DPM1/grid");
              } else if (jobType == "fpm1") {
                window.open("http://52.220.67.132:8080/dags/FPM1/grid");
              } else if (jobType == "dpm2") {
                window.open("http://52.220.67.132:8080/dags/DPM2/grid");
              } else if (jobType == "fpm2") {
                window.open("http://52.220.67.132:8080/dags/FPM2/grid");
              }
              //update variables values
              fetch(apiUrl, requestOptions)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.json();
                })
                .then((data) => {
                  console.log("my data is ", data);
                })
                .catch((error) => {
                  console.error("Error:", error);
                });

              //call dags
              fetch(dagUrl, dag_requestOptions)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.json();
                })
                .then((data) => {
                  console.log("my data is ", data);
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            },
          },
          {
            label: "No",
            onClick: () => alert("Job not started"),
          },
        ],
      });
    } else {
      console.log("is empty");
    }
  };

  console.log(`calc(100vh - ${appBarHeight}px)`);

  const handleSideBarDisplay = (title, description) => {
    setSidebarDescription({ title: title, description: description });
    handleDrawerOpen();
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onEachClick = (feature, layer, title, description) => {
    // const name = feature.properties.name;
    // const density = feature.properties.density;

    layer.on({
      click: (e) => {
        handleFeatureClick(e, title, description);
      },
    });
  };

  const handleFeatureClick = (e, title, description) => {
    setOpenLayers(false);
    if (!geoJsonRef.current) return;
    geoJsonRef.current.resetStyle();

    const layer = e.target;
    handleSideBarDisplay(title, description);

    layer.setStyle({ color: "#cc7076AA" });
  };

  const highlightedTextFieldStyle = (dpm1Hover) => ({
    border: dpm1Hover
      ? `2px solid ${theme.palette.primary.main}`
      : "2px solid transparent", // Use primary color
    boxShadow: dpm1Hover ? `0 0 5px ${theme.palette.primary.main}33` : "none", // Subtle shadow with primary color
    transition: "border 0.3s ease, box-shadow 0.3s ease", // Smooth transition
    borderRadius: "8px", // Rounded edges
  });

  const handleJobTypeChange = (event) => {
    setJobType(event.target.value);
  };

  const handleAWSJobSubmit = () => {
    dpm1Get();
  };

  const SarfinderPanel = (
    <SidePanel setOpenLayers={setOpenLayers} openLayers={openLayers}>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ width: "100%", marginBottom: 2 }} spacing={2}>
          <Grid item xs={12} md={4}>
            <DateTimePicker
              label="Start Date and Time"
              inputVariant="outlined"
              // defaultValue={dayjs("2022-04-17T15:30")}
              value={formData.startDateTime}
              onChange={(val) => {
                handleDateTimeChange(val, "startDateTime");
              }}
              fullWidth
              margin="normal"
              format="YYYY-MM-DD T HH:MMz"
              //for DPM1
              sx={{
                ...highlightedTextFieldStyle(isSubmitHover),
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateTimePicker
              label="End Date and Time"
              inputVariant="outlined"
              // defaultValue={dayjs("2022-04-17T15:30")}
              value={formData.endDateTime}
              onChange={(val) => {
                handleDateTimeChange(val, "endDateTime");
              }}
              fullWidth
              margin="normal"
              format="YYYY-MM-DD T HH:MMz"
              //for DPM1
              sx={{
                ...highlightedTextFieldStyle(isSubmitHover),
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="platformName-label">Platform</InputLabel>
              <Select
                labelId="platformName-label"
                id="platformName"
                name="platformName"
                label="Platform"
                multiple
                value={formData.platformName}
                onChange={handleChange}
              >
                <MenuItem value="alos2">ALOS-2</MenuItem>
                <MenuItem value="alos1">ALOS-1</MenuItem>
                <MenuItem value="sentinel1">Sentinel-1</MenuItem>
                <MenuItem value="cosmo_skymed">Cosmo Skymed</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="beamMode-label">Beam Mode</InputLabel>
              <Select
                labelId="beamMode-label"
                id="beamMode"
                name="beamMode"
                label="Beam Mode"
                multiple
                value={formData.beamMode}
                onChange={handleChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="SM1">SM1 (Stripmap 3m)</MenuItem>
                <MenuItem value="SM2">SM2 (Stripmap 6m)</MenuItem>
                <MenuItem value="SM3">SM3 (Stripmap 10m)</MenuItem>
                <MenuItem value="SPT">SPT (Spotlight)</MenuItem>
                <MenuItem value="WD1">WD1 (ScanSAR)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="orbitDirection-label">Orbit Direction</InputLabel>
              <Select
                labelId="orbitDirection-label"
                id="orbitDirection"
                name="orbitDirection"
                label="Orbit Direction"
                value={formData.orbitDirection}
                onChange={handleChange}
              >
                <MenuItem value="">Both</MenuItem>
                <MenuItem value="Ascending">Ascending</MenuItem>
                <MenuItem value="Descending">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="relOrbitNumber"
                name="relOrbitNumber"
                label="Relative Orbit"
                variant="outlined"
                // InputLabelProps={{ shrink: true }}
                value={formData.relOrbitNumber}
                onChange={handleChange}
                //for DPM1
                style={highlightedTextFieldStyle(isSubmitHover)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="endRelOrbitNumber"
                name="endRelOrbitNumber"
                label="End Relative Orbit"
                variant="outlined"
                value={formData.endRelOrbitNumber}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="frameNumber"
                name="frameNumber"
                label="Frame"
                variant="outlined"
                value={formData.frameNumber}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="endFrameNumber"
                name="endFrameNumber"
                label="End Frame"
                variant="outlined"
                value={formData.endFrameNumber}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="identifier"
                name="identifier"
                label="Identifier"
                variant="outlined"
                value={formData.identifier}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                showLoadingScreen();

                const fetchPromises = formData.platformName.map((platform) => {
                  return GeoJSONMap(
                    "https://sf.earthobservatory.sg:8443/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=EOS:" +
                      platform +
                      "&outputFormat=application/json" +
                      getFilters()
                  );
                });

                Promise.all(fetchPromises)
                  .then((promises) => {
                    var result_array = promises.map((result) => {
                      return result;
                    });

                    setJsonData(result_array);
                    enqueueSnackbar(`Data loaded`, {
                      variant: "info",
                    });
                    // promises.forEach((promise) => {
                    //   setJsonData((prevData) => {
                    //     return [...prevData, promise];
                    //   });
                    // });
                  })
                  .catch((error) => {
                    enqueueSnackbar(`Error fetching data: ${error}`, {
                      variant: "error",
                    });

                    hideLoadingScreen();
                  })
                  .finally(() => {
                    hideLoadingScreen();
                  });
              }}
            >
              Submit
            </Button>
          </Grid>

          {/* DPM1 */}
          {/* ----------------------------------------------------------------------------------- */}

          {/* Directory Name text field for DPM1 */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="directoryName"
                name="directoryName"
                label="Directory Name (e.g. TD123_Libya_Earthquake)"
                variant="outlined"
                value={formData.directoryName}
                onChange={handleChange}
                style={highlightedTextFieldStyle(isSubmitHover)}
              />
            </FormControl>
          </Grid>

          {/* Master Date text field for DPM1 */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="masterDate"
                name="masterDate"
                label="Master Date in yyyymmdd (e.g. 20230831)"
                variant="outlined"
                value={formData.masterDate}
                onChange={handleChange}
                style={highlightedTextFieldStyle(isSubmitHover)}
              />
            </FormControl>
          </Grid>

          {/* Event Date text field for DPM1 */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="eventDate"
                name="eventDate"
                label="Event Date in yyyymmdd (e.g. 20230831)"
                variant="outlined"
                value={formData.eventDate}
                onChange={handleChange}
                style={highlightedTextFieldStyle(isSubmitHover)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider
          sx={{
            marginY: "1rem",

            width: "100%",
            borderColor: "rgba(255, 255, 255, 0.7)",
          }}
        />
        <Typography
          variant="h6" // Use a typography variant for styling
          color="white" // Adjust text color for better readability on dark background
          sx={{ marginBottom: "1rem" }} // Space below the title
          textAlign={"center"}
        >
          AWS HPC Controls
        </Typography>
        {/* to submit jobs to AWS HPC*/}
        <Grid container sx={{ width: "100%", marginBottom: 2 }} spacing={2}>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="job-type-select-label">Job Type</InputLabel>
              <Select
                labelId="job-type-select-label"
                value={jobType}
                onChange={handleJobTypeChange}
                label="Job Type"
              >
                <MenuItem value="dpm1">Start DPM1</MenuItem>
                <MenuItem value="fpm1">Start FPM1</MenuItem>
                <MenuItem value="dpm2">Start DPM2</MenuItem>
                <MenuItem value="fpm2">Start FPM2</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={4}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAWSJobSubmit}
              onMouseOver={() => setIsSubmitHover(true)}
              onMouseLeave={() => setIsSubmitHover(false)}
              style={{
                backgroundColor: isSubmitHover ? "#ffffff" : "#ffbd5a", // Adjust colors as needed
                transition: "background-color 0.3s ease",
                height: "100%",
              }}
            >
              Submit Job
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                window.open(
                  "https://s76at197s6.execute-api.us-east-1.amazonaws.com"
                );
              }}
            >
              Open AWS Fleet{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </SidePanel>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <DownloadsPopup
        open={openDownload}
        onClose={handleCloseDownload}
        downloads={checked}
      /> */}

      <AppHeader
        position="fixed"
        isMapPage
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        ref={appBarRef}
      />
      {SarfinderPanel}

      <CustomDrawer open={open}>
        <DrawerHeader>
          <Typography
            variant="h5"
            sx={{ overflowWrap: "anywhere" }}
            color={"primary"}
          >
            {state?.event?.event_display_name}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack sx={{ padding: "1rem", gap: "1rem" }}>
          <Typography variant="h5" sx={{ textDecoration: "underline" }}>
            Product Description
          </Typography>
          <Typography variant="h6" sx={{ lineBreak: "anywhere" }}>
            {sidebarDescription?.title}
          </Typography>
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            {sidebarDescription?.description}
          </Typography>
        </Stack>
        <Divider />
      </CustomDrawer>

      <Main
        open={open}
        style={{
          height: `calc(100vh - ${appBarHeight}px)`,
          marginTop: `${appBarHeight}px`,
        }}
      >
        <MapView setSelectionArea={setSelectionArea} geoJsonRef={geoJsonRef}>
          {jsonData?.map((platformName, index) => {
            return platformName?.features?.map((item) => {
              return (
                <GeoJSON
                  style={{ color: outline_color[index], weight: 2 }}
                  ref={geoJsonRef}
                  onEachFeature={(feature, layer) => {
                    onEachClick(
                      feature,
                      layer,
                      decodeURIComponent(escape(item?.id)),
                      // decodeURIComponent(escape(item?.properties))
                      JSON.stringify(item?.properties, null, 2)
                    );
                  }}
                  key={item?.id}
                  data={item}
                />
              );
            });
          })}
        </MapView>

        <SlidingTable jsonData={jsonData} />
      </Main>
    </Box>
  );
}

export default SarfinderPage;
