import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Grid, TextField } from "@mui/material";
import AppHeader from "components/AppHeader/AppHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRoute } from "utils/routes";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "provider/auth/useUser";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";
import Cookies from "js-cookie";

const schema = yup.object().shape({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export default function PasswordUpdate() {
  const navigate = useNavigate();
  const userQuery = useUser();
  const [searchParams] = useSearchParams();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);
  const [tokenErrorMessage, setTokenErrorMessage] = useState("");

  const passwordResetToken = searchParams.get("token");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if (!userQuery.isLoading && userQuery.data?.username) {
      navigate(getRoute("home"));
    }
  }, [userQuery, navigate]);

  useEffect(() => {
    if (!passwordResetToken) {
      navigate(getRoute("home"));
    } else {
      async function checkTokenValidity() {
        const response = await fetch(
          `${SARFINDER_BE_API_URL}/accounts/password_reset/validate_token/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
            },
            body: JSON.stringify({ token: passwordResetToken }),
            credentials: "include",
          }
        );

        if (!response.ok) {
          const data = await response.json();
          setTokenValid(false);
          setTokenErrorMessage(data.detail);
        } else {
          setTokenValid(true);
        }
      }

      checkTokenValidity();
    }
  }, [navigate, passwordResetToken]);

  const onSubmit = async (data) => {
    if (!tokenValid) {
      //* Prevent submission if the token is invalid
      return;
    }

    try {
      const response = await fetch(
        `${SARFINDER_BE_API_URL}/accounts/password_reset/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
          },
          body: JSON.stringify({
            password: data.password,
            token: passwordResetToken,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        setResetSuccess(true);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Password reset failed");
      }
    } catch (error) {
      console.error(error);
      setTokenValid(false);
      setTokenErrorMessage(error.message);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppHeader />

      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ maxWidth: 900, margin: "auto" }}
            >
              <Typography variant="h4" gutterBottom textAlign={"center"}>
                Update your password
              </Typography>

              {!tokenValid ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom textAlign={"justify"}>
                    {tokenErrorMessage ||
                      "The token is invalid. Please try again."}
                  </Typography>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => navigate(getRoute("home"))}
                  >
                    Head back home
                  </Button>
                </Box>
              ) : resetSuccess ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom textAlign={"justify"}>
                    Password reset successfully!
                  </Typography>

                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => navigate(getRoute("home"))}
                  >
                    Head back home
                  </Button>
                </Box>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom textAlign={"justify"}>
                    Please enter your new password below.
                    <strong>
                      {" "}
                      Ensure that your password is secure and not shared with
                      anyone else.
                    </strong>
                  </Typography>

                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="New Password"
                        type="password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        fullWidth
                        margin="normal"
                        required
                      />
                    )}
                  />
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Confirm New Password"
                        type="password"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                        fullWidth
                        margin="normal"
                        required
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Reset Password
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
