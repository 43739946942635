import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { CSRF_COOKIE_NAME, SARFINDER_BE_API_URL } from "utils/constants";

export function useLogout() {
  const queryClient = useQueryClient();

  const logoutMutation = useMutation({
    mutationFn: () => {
      return fetch(`${SARFINDER_BE_API_URL}/accounts/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get(CSRF_COOKIE_NAME),
        },
        credentials: "include",
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Logout failed");
        }
        return res.json();
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["auth:user"] });
    },
    onError: (error) => {
      console.error("Logout error:", error);
    },
  });

  const logout = async () => {
    return await logoutMutation.mutateAsync();
  };

  return {
    logout,
    isLoading: logoutMutation.isPending,
    error: logoutMutation.error,
  };
}
