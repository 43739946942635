//Gets sets and removes the Auth Token Retrieved from Strapi
//Stores in local storage
import { AUTH_TOKEN } from "./constants";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
export const handleDownload = (url) => {
  // Implement your download logic here
  window.open(url, "_blank"); // Example: Opens the download URL in a new tab
};

export const parseXmlError = (xmlString) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");

    const errorInfo = {
      // exceptionCode: exceptionNode.getAttribute("exceptionCode"),
      // locator: exceptionNode.getAttribute("locator"),
      exceptionText:
        xmlDoc.getElementsByTagName("ows:ExceptionText")[0].textContent,
    };

    return errorInfo;
  } catch (error) {
    console.error("Error parsing XML:", error);
    return null;
  }
};
